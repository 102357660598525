const formatDate = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  }

  return new Date(date).toLocaleDateString("fr-FR", options)
}

const hasBeenUpdated = (creationDate: string, editedDate: string) =>
  editedDate && new Date(creationDate) < new Date(editedDate)

export { formatDate, hasBeenUpdated }
