import { Layout } from "components/Layout"
import { Link, navigate, PageProps } from "gatsby"

interface Props {
  frontmatter: {
    title: string
  }
  id: number
  html: string
}

const WpPage = ({ pageContext }: PageProps<null, Props>) => {
  return (
    <Layout pageTitle={`${pageContext?.frontmatter?.title}`}>
      <div className={`post-${pageContext.id}`}>
        <div dangerouslySetInnerHTML={{ __html: pageContext.html }} />
      </div>
    </Layout>
  )
}

export default WpPage
