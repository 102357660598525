import {
  AddCircleOutlineOutlined,
  DriveFileRenameOutlineOutlined,
} from "@mui/icons-material"
import { formatDate } from "libs/dateHelper"
import { FC } from "react"

interface Props {
  date: string
  isText?: boolean
}

export const DateFormatted: FC<Props> = ({ date, isText = true }) => {
  const formatted = formatDate(date)
  return isText ? (
    <span>rédigé le {formatted}</span>
  ) : (
    <span>
      <AddCircleOutlineOutlined
        sx={{ fontSize: "0.7rem", verticalAlign: "sub", marginRight: "0.1rem" }}
      />
      <span>{formatted}</span>
    </span>
  )
}

export const DateEditedFormatted: FC<Props> = ({ date, isText = true }) => {
  const formatted = formatDate(date)

  return isText ? (
    <span>mis à jour le {formatted}</span>
  ) : (
    <span>
      <DriveFileRenameOutlineOutlined
        sx={{ fontSize: "0.7rem", verticalAlign: "sub", marginRight: "0.1rem" }}
      />
      <span>{formatted}</span>
    </span>
  )
}
