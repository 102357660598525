import { Chip, Stack, Typography } from "@mui/material"
import AuthorBio from "components/AuthorBio"
import { Layout } from "components/Layout"
import { navigate, PageProps } from "gatsby"

interface Props {
  frontmatter: {
    date: string
    lastUpdate: string
    title: string
    tags: string[]
    categories: string[]
  }
  html: string
}

const WpPost = ({ pageContext }: PageProps<null, Props>) => {
  const {
    frontmatter: { date, lastUpdate, title, tags, categories },
    html,
  } = pageContext
  return (
    <Layout pageTitle={title} hasNav={false} isSmallTitle>
      <main>
        <Typography
          variant="h1"
          className="post-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <Stack
          direction="row"
          spacing={1}
          sx={{ flexWrap: "wrap", rowGap: "0.3rem", margin: "3rem 0 1rem" }}
        >
          {tags?.length &&
            tags.map((tag: string) => (
              <Chip
                key={tag}
                label={tag}
                onClick={() => navigate(`/?tag=${tag}`)}
                size="small"
                sx={{ cursor: "pointer" }}
                variant="outlined"
                title="Tag"
              />
            ))}
        </Stack>
        <Stack direction={"row"} spacing={2} paddingBottom={4}>
          {categories.map((c: string) => (
            <Chip
              color="primary"
              key={c}
              label={c}
              onClick={() => navigate(`/?category=${c}`)}
              variant="outlined"
              title="Catégorie"
            />
          ))}
        </Stack>
        <AuthorBio name="Behrouze" date={date} lastUpdate={lastUpdate} />
      </main>
    </Layout>
  )
}

export default WpPost
