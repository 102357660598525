import { Link as MuiLink } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { graphql, Link, useStaticQuery } from "gatsby"
import { slugFromMdPath } from "libs/stringHelper"

interface nodePage {
  frontmatter: { title: string }
  fileAbsolutePath: string
}

export const NavToPages = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { categories: { eq: null } } }
        sort: { fields: frontmatter___title }
      ) {
        nodes {
          frontmatter {
            title
          }
          fileAbsolutePath
        }
      }
    }
  `)

  const pages = data?.allMarkdownRemark?.nodes

  return (
    !!pages &&
    pages.map((page: nodePage) => {
      const {
        frontmatter: { title },
        fileAbsolutePath,
      } = page
      const uri = slugFromMdPath(fileAbsolutePath)

      return (
        <MuiLink
          component={Link}
          to={`/${uri}`}
          underline="none"
          key={uri}
          sx={{ marginRight: "2rem" }}
          color={blueGrey[800]}
        >
          {title}
        </MuiLink>
      )
    })
  )
}
