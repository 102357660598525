import "components/Layout.css"
import { NavToCategories } from "./NavToCategories"
import { Search } from "./Search"

export const NavDesktop = () => {
  return (
    <ul className="nav-links">
      <li>
        <NavToCategories />
      </li>
      <li>
        <Search />
      </li>
    </ul>
  )
}
