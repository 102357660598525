import { Box, Chip, Link as MuiLink } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { DateEditedFormatted, DateFormatted } from "components/DateFormatted"
import { Layout } from "components/Layout"
import { TitleFilter } from "components/TitleFilter"
import { graphql, Link, navigate, PageProps } from "gatsby"
import { hasBeenUpdated } from "libs/dateHelper"
import { slugFromMdPath } from "libs/stringHelper"
import { useEffect, useState } from "react"

const IndexPage = ({ data }: PageProps<Queries.MainPageQuery>): JSX.Element => {
  const {
    allMarkdownRemark: { nodes },
  } = data
  const [queryTag, setQueryTag] = useState<string>("")
  const [queryCategory, setQueryCategory] = useState<string>("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      setQueryTag(new URL(window.location.href).searchParams.get("tag"))
      setQueryCategory(
        new URL(window.location.href).searchParams.get("category")
      )
    }
  })

  return (
    <Layout pageTitle="Full Stack & Devops" hasNav={true} isSmallTitle={false}>
      <TitleFilter isCategory={!!queryCategory} slug={queryTag} />

      {nodes.map((node) => {
        const { categories, tags, title, date, lastUpdate } = node.frontmatter
        if (!title) return null

        const isFiltered = !!queryCategory || !!queryTag
        const nodeCategories = categories?.includes(queryCategory)
        const nodeTags = tags?.includes(queryTag)

        if (!isFiltered || (isFiltered && (nodeCategories || nodeTags))) {
          const slug = slugFromMdPath(node.fileAbsolutePath)
          return (
            <div key={slug} style={{ margin: "0.7rem 0 0 0" }}>
              <MuiLink
                component={Link}
                to={slug}
                underline="none"
                sx={{ display: "inline", margin: "0 0.3rem 0 0" }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <span
                style={{
                  color: blueGrey["300"],
                  fontSize: "0.7rem",
                  margin: "0 0 0 0.2rem",
                }}
              >
                {hasBeenUpdated(date, lastUpdate) ? (
                  <DateEditedFormatted date={lastUpdate} isText={false} />
                ) : (
                  <DateFormatted date={date} isText={false} />
                )}
              </span>
              <Box
                sx={{
                  transition: "opacity 0.2s",
                  opacity: "0",
                  "&:hover": {
                    opacity: "1",
                  },
                  display: {
                    xs: "none",
                    sm: "inline",
                  },
                }}
              >
                {!!tags &&
                  tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      onClick={() => navigate(`/?tag=${tag}`)}
                      size="small"
                      sx={{
                        cursor: "pointer",
                        marginLeft: "0.3rem",
                        color: blueGrey["300"],
                      }}
                      variant="outlined"
                      title="Tag"
                    />
                  ))}
              </Box>
            </div>
          )
        }
      })}
    </Layout>
  )
}

export const query = graphql`
  query MainPage {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { categories: { ne: null } } }
    ) {
      nodes {
        frontmatter {
          title
          categories
          tags
          date
          lastUpdate
        }
        id
        fileAbsolutePath
      }
    }
  }
`

export default IndexPage
