import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { graphql, navigate, PageProps, useStaticQuery } from "gatsby"

export const Search = () => {
  const data: PageProps<Queries.SearchQuery> = useStaticQuery(graphql`
    query Search {
      allMarkdownRemark(filter: { frontmatter: { tags: { ne: null } } }) {
        nodes {
          frontmatter {
            tags
          }
        }
      }
    }
  `)

  const {
    allMarkdownRemark: { nodes },
  } = { ...data }

  let allTagsArrays: Array<string> = []

  nodes.forEach((node) => {
    const {
      frontmatter: { tags },
    } = node

    allTagsArrays = [...allTagsArrays, ...tags]
  })

  const allTags = allTagsArrays
    .sort()
    .reduce((acc: any, curr: string, idx: number) => {
      if (Object.keys(acc).includes(curr)) acc[curr]++
      else acc[curr] = 1
      return acc
    }, {})

  const tagsToDisplay = Object.keys(allTags).map((tag) => {
    return {
      label: `${tag} (${allTags[tag]})`,
      id: tag,
    }
  })

  return (
    <Autocomplete
      autoComplete
      blurOnSelect
      color="primary"
      disablePortal
      sx={{ width: 200 }}
      size="small"
      options={tagsToDisplay}
      renderInput={(params) => (
        <TextField {...params} color="primary" label="Recherche par Tag" />
      )}
      onChange={(_event, newValue: { id: string }) => {
        const url = !!newValue?.id ? `/?tag=${newValue.id}` : "/"
        navigate(url)
      }}
    />
  )
}
