import { Button, Link as MuiLink, Menu, MenuItem } from "@mui/material"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useState } from "react"

export const NavToCategories = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { categories: { ne: null } } }) {
        nodes {
          frontmatter {
            categories
          }
        }
      }
    }
  `)

  let allCategories: Array<string> = []

  allMarkdownRemark.nodes.forEach((node) => {
    node.frontmatter?.categories?.forEach((c) => allCategories.push(c))
  })

  const categories = Array.from(new Set(allCategories.sort()))

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Categories
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categories.map((cat) => {
          return (
            <MuiLink
              component={Link}
              to={`/?category=${cat}`}
              underline="none"
              key={cat}
            >
              <MenuItem onClick={handleClose}>{cat}</MenuItem>
            </MuiLink>
          )
        })}
      </Menu>
    </>
  )
}
