import clsx from "clsx"
import "components/Layout.css"
import { Link as GatsbyLink } from "gatsby"
import { FC, ReactNode } from "react"
import { Helmet } from "react-helmet"

import { Container, CssBaseline, Typography } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Box } from "@mui/system"

import { NavDesktop } from "./Nav/NavDesktop"
import { NavMobile } from "./Nav/NavMobile"
import { NavToPages } from "./Nav/NavToPages"

interface IProps {
  pageTitle?: string
  hasNav?: boolean
  isSmallTitle?: boolean
  children: ReactNode
}

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "2rem",
      marginTop: "2rem",
    },
  },
})

export const Layout: FC<IProps> = ({
  pageTitle,
  hasNav = true,
  children,
  isSmallTitle = false,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang="fr" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Blog technique et mémo sur les technos .Net et Javascript React ainsi que l'outillage devops"
        />
        <link rel="canonical" href="https://blog.behrouze.com" />
        <meta name="msvalidate.01" content="A50293F74A3B85CC8957428EEEEAABA5" />
        <title>{pageTitle}</title>
        <script src="/mtm.js" />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="md">
        <a href="/" style={{ textDecoration: "none" }}>
          <Typography
            variant="h1"
            gutterBottom
            className={clsx(isSmallTitle && "small", "website")}
          >
            Développeur FullStack & Devops
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ color: "text.secondary" }}
          >
            Notes et mémos techniques
          </Typography>
        </a>
        {hasNav && (
          <>
            <NavDesktop />
            <NavMobile />
          </>
        )}
        {children}
        <footer>
          <Box
            sx={{
              borderTop: `1px solid ${blueGrey[300]}`,
              margin: "2rem 0",
              padding: "1.5rem 0",
              display: { xs: "none", sm: "block" },
            }}
          >
            <NavToPages />
          </Box>
        </footer>
      </Container>
    </ThemeProvider>
  )
}
