import { Typography } from "@mui/material"
import { DateEditedFormatted, DateFormatted } from "components/DateFormatted"
import { hasBeenUpdated } from "libs/dateHelper"
import { FC } from "react"
interface IProps {
  name: string
  date: string
  lastUpdate: string
}

const AuthorBio: FC<IProps> = ({ name, date, lastUpdate }) => {
  return (
    <Typography
      variant="subtitle1"
      component="i"
      sx={{ color: "text.disabled" }}
    >
      <div>
        <DateFormatted date={date} />
        {hasBeenUpdated(date, lastUpdate) && (
          <span>
            , <DateEditedFormatted date={lastUpdate} />
          </span>
        )}
        <span> par {name}</span>
      </div>
    </Typography>
  )
}

export default AuthorBio
