import {
  Build,
  FileCopyOutlined,
  Person,
  Search as SearchIcon,
  VideoLibrary,
} from "@mui/icons-material"
import { Backdrop, SpeedDial, SpeedDialAction } from "@mui/material"
import { navigate } from "gatsby"
import { useState } from "react"

const actions = [
  { icon: <Build />, name: "Tools" },
  { icon: <VideoLibrary />, name: "Vidéos" },
  { icon: <FileCopyOutlined />, name: "CheatSheets" },
  { icon: <Person />, name: "About" },
]

export const NavMobile = () => {
  const [openSpeedDial, setOpenSpeedDial] = useState(false)
  const handleOpen = () => setOpenSpeedDial(true)
  const handleClose = (e: React.SyntheticEvent | {}, action: string) => {
    switch (action) {
      case "About":
        navigate("/a-propos-contact")
        break
      case "CheatSheets":
        navigate("/cheat-sheets")
        break
      case "Tools":
        navigate("/tools")
        break
      case "Vidéos":
        navigate("/must-see-videos")
        break
    }

    setOpenSpeedDial(false)
  }

  return (
    <>
      <Backdrop open={openSpeedDial} />
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          display: { sm: "none" },
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        icon={<SearchIcon />}
        onClose={(e) => handleClose(e, "")}
        onOpen={handleOpen}
        open={openSpeedDial}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={(e) => handleClose(e, action.name)}
          />
        ))}
      </SpeedDial>
    </>
  )
}
