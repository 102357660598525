import { Typography } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { FC } from "react"

interface IProps {
  isCategory: boolean
  slug: string
}

export const TitleFilter: FC<IProps> = ({ isCategory, slug }) => {
  if (!slug) return null

  return (
    <Typography variant="h3" color={blueGrey[500]}>
      {`${isCategory ? "Catégorie" : "Tag"} : ${slug}`}
    </Typography>
  )
}
